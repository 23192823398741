import React, { useRef, useEffect, useState } from 'react'
import useOnScreen from '../hooks/useOnScreen';
import downloadImage from '../helpers/downloadImage';
import { Link } from 'gatsby';

const ParallaxImage = ({
	className,
	src,
	srcSet,
	alt,
	sizes,
	linksTo,
	linkClassName='',
	linkTarget='_self',
}) => {

	const handleOnKeyDown = e => {
		if (e.key === 'Enter') {
			onDownloadClick();
		}
	}

	const onDownloadClick = () => {
		let filename = src;
		if (filename.includes("prismic"))
			filename = filename.substring(71,filename.length).split('?')[0];

		filename = decodeURIComponent(filename);
		downloadImage(src, filename);
	}

	const containerEl = useRef(null)
	const containerElIsOnScreen = useOnScreen(containerEl)
	const imageEl = useRef(null)
	const imageElIsOnScreen = useOnScreen(imageEl)
	const [isImageLoaded, setIsImageLoaded] = useState(false)

	const imageScale = 1.3

	// Check if image is loaded
	useEffect(() => {
		if (isImageLoaded === true) return;
		if (imageEl.current.complete && imageEl.current.naturalHeight !== 0) {
			setIsImageLoaded(true)
		}
		imageEl.current.addEventListener('load', handleImageLoaded)
		const handleImageLoaded = () => {
			setIsImageLoaded(true)
		}
		return () => {
			imageEl.current.removeEventListener('load', handleImageLoaded)
		}
	}, [containerElIsOnScreen, isImageLoaded])

	// TO DO: have only one global scroll event for performances?
	useEffect(() => {
		if (localStorage.getItem('is-touch') === 'true') return

		let wh = 0
		let baseDistance = 0
		let distanceToTranslate = 0
		let newY = 0

		const handleResize = () => {
			wh = window.innerHeight
			const containerHeight = containerEl.current.getBoundingClientRect().height
			const imageHeight = imageEl.current.getBoundingClientRect().height
			distanceToTranslate = imageHeight - containerHeight
			baseDistance = wh + (containerHeight * 2)
		}

		const handleScroll = e => {
			const containerBottom = containerEl.current.getBoundingClientRect().bottom
			newY = (containerBottom / baseDistance) * distanceToTranslate
			requestAnimationFrame(loop)
		}

		const loop = () => {
			imageEl.current.style.transform = `scale(${imageScale}) translate3d(0, ${-newY}px, 0)`
		}

		const reset = () => {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleResize)
		}

		if (containerElIsOnScreen === true && isImageLoaded === true) {
			window.addEventListener('scroll', handleScroll)
			window.addEventListener('resize', handleResize)
			loop()
			handleResize()
		} else {
			reset()
		}

		handleResize()
		handleScroll()

		return () => {
			reset()
		}
	}, [containerElIsOnScreen, isImageLoaded])

	const sourceSets = srcSet.split(', ');
	const displayedSources = [];
	sourceSets.forEach(source => {
		const splitSet = source.split(' ');
		displayedSources.push(<source media={`(min-width:${splitSet[1]})`} srcset={splitSet[0]}></source>)
	});

	const pictureSimple = <picture>
		{displayedSources}
		<img
			className={`ParallaxImage__Image ${imageElIsOnScreen ? 'onScreen' : ''}`}
			src={src}
			srcSet={srcSet}
			alt={alt}
			sizes={sizes}
			ref={imageEl}
		/>
	</picture>;

	const pictureComponent = linksTo ? <Link className={linkClassName} target={linkTarget} to={linksTo}>{pictureSimple}</Link> : pictureSimple;

	return (
		<div
			className={`ParallaxImage ${className} ${linkClassName}`}
			data-is-on-screen
		>
			
			<div className="ParallaxImage__Container">
				<div
					className="ParallaxImage__Wrapper"
					ref={containerEl}
				>

					<div
						className="DownloadableImage__Button"
						onClick={onDownloadClick}
						onKeyDown={handleOnKeyDown}
						role="button"
						tabIndex={0}
						data-cursor-hide
					>
						<span className="DownloadableImage__ButtonText">Download Image</span>
						<svg className="DownloadableImage__ButtonIcon" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)" stroke="#fff"><path d="M4 0v7.188M.277 3.884L4 7.474l3.724-3.59"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="rotate(90 4 4)" d="M0 0h8v8H0z"/></clipPath></defs></svg>
					</div>
						
					{pictureComponent}
					
				</div>
			</div>
		</div>
	)
}

export default ParallaxImage